function DeveloperTraining() {

    return (
        <div>
            <center>
                <h1 className="text-5xl font-bold mb-6 py-8">
                    Developer Training and Coaching
                </h1>
                <p className="text-lg mb-10">
                    Provide senior-level training, reviews, debugging, and more for your developers to increase productivity and efficiency.
                </p>
                <p className="text-lg mb-10">
                    On-site (Additional Cost) or remote training, pair programming, code review, debugging sessions, consultation services, and repeatable tooling and processes.
                </p>
                <p className="text-lg mb-10">
                    Extensive experience mentoring and enhancing developer teams as Lead and Manager for Mobile, Frontend, and Fullstack teams. Maintained a continuous feedback loop through regular 1:1s and an open-door policy, fostering growth from programming fundamentals to advanced topics like memory leak detection and algorithm optimization. Dedicated significant time to code reviews and live debugging sessions, both in-person and remotely, to ensure quality and developer growth.
                    <br /><br />
                    Implemented and championed development best practices, including:
                    <br /><br />
                    CI/CD tools for automated code quality checks.
                    Testing frameworks (e.g., Jest, Appium, Puppeteer, Cypress) for unit, integration, and UI testing.
                    Localization (i18n) and optimization for right-to-left languages.
                    AI-assisted tools and training for efficient development.
                    Advocated for professional development by encouraging conference participation and introducing team-wide initiatives like dedicated testing days. Continuously improved workflows to enhance productivity, app quality, and developer confidence.
                </p>
            </center>
        </div>
    )

}

export default DeveloperTraining;