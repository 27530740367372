function ConsumerApps() {

    return (
        <div>
            <h1>Consumer Apps</h1>
        </div>
    )

}

export default ConsumerApps;