function Consulting() {

    return (
        <div>
            <center>
                <h1 className="text-5xl font-bold mb-6 py-8">
                    Technical Consulting
                </h1>
                <p className="text-lg mb-10">
                    Technical consulting services on system architecture, best practices, focus areas, and business brainstorming.
                </p>
                <p className="text-lg mb-10">
                    Detailed documentation with original content, proposed solutions, and clear next steps and recommendations.
                </p>
                <p className="text-lg mb-10">
                    Extensive consulting experience across topics such as System Architecture and Design, Process Improvement, Monetization Strategies, and Gamification. Worked with diverse clients, including travel site aggregators, local business point-of-sale systems, and job search API services. For a travel site client, designed a system and data architecture to aggregate and visualize location-based blog posts that were mobile-friendly and high-performance.
                    <br /><br />
                    Consultation process begins with in-depth discussions to understand client needs, challenges, and goals, followed by independent research involving market analysis, competitor evaluation, and testing existing solutions. Delivered comprehensive documentation detailing findings, identifying potential unstated problems, and proposing multiple solutions with pros/cons, cost analysis, and risk assessments. Ensures collaborative communication and results-driven strategies to meet both immediate and long-term client needs.
                </p>
            </center>
        </div>
    )

}

export default Consulting;