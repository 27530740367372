import React from 'react';
import { Container, Typography } from '@mui/material';

function Home() {
  return (
    <Container>
      <Typography paragraph variant='h4'>Node Reactor Server Start</Typography>
    </Container>
  );
}

export default Home;
