function SolutionsArchitect() {

    return (
        <div>
            <center>
                <h1 className="text-5xl font-bold mb-6 py-8">
                    Solutions Architect and Team Building
                </h1>
                <p className="text-lg mb-10">
                    Business Process Map, roadmap with executable steps, realistic timelines, trade-off analysis, and working prototypes (mockups or interactive sites). Client meetings may incur additional costs.
                </p>
                <p className="text-lg mb-10">
                    Business Process Map, roadmap with executable steps, realistic timelines, trade-off analysis, and working prototypes (mockups or interactive sites). Client meetings may incur additional costs.
                </p>
                Why Chose Us?
                <p className="text-lg mb-10">
                    Five years of experience as a Solutions Architect, specializing in aligning business needs with scalable, future-proof system capabilities. Collaborated with clients and stakeholders to understand requirements, created detailed process maps aligned with use cases, and translated them into comprehensive system solutions. Acted as a bridge between business and technical teams, ensuring seamless communication and alignment. Evaluated systems and recommended technology stacks to support client goals while identifying gaps and designing solutions in collaboration with product owners and designers. Delivered iterative, scalable solutions for clients such as BMW, Arval, and Merchants Auto (subject to NDA), incorporating considerations for security, compliance, and evolving business needs. Provided ongoing support to adapt solutions in dynamic environments, ensuring long-term value.
                </p>
            </center>
        </div>
    )

}

export default SolutionsArchitect;