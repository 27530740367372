function Design() {

    return (
        <div>
            <center>
                <h1 className="text-5xl font-bold mb-6 py-8">
                    Graphics, UI and UX Design
                </h1>
                <p className="text-lg mb-10">
                    Collaborate to craft the perfect design and develop visual assets like; mockups, sprite sheets, an infographic and more.
                </p>
                <p className="text-lg mb-10">
                    Initial setup, up to 3 mockup variants, and detailed review sessions. Additional costs for refreshes or extensive revisions.
                </p>
                <p className="text-lg mb-10">
                    Bachelor’s degree in Game and UX Design, with hands-on experience creating mockups, sprite sheets, and infographics for personal projects (e.g., Codeductor, Ultra RPS) and professional roles. Proficient in industry-standard tools like Figma, Aesprite, Photoshop, and Illustrator, as well as workflow and mockup tools like Draw.io and Miro. Collaborated with clients both in person and remotely to translate ideas into visuals, working alongside experienced artists with over a decade of analog and digital art expertise.
                </p>
            </center>
        </div>
    )

}

export default Design;