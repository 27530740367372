function TermsOfService() {

    return (
        <div>
            <h1>Terms Of Service</h1>
        </div>
    )

}

export default TermsOfService;