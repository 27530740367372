import createTheme from '@mui/material/styles/createTheme.js';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF8769',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#464646',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h6: {
      color: '#000000', // Black text for headers
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },
        body: {
          backgroundColor: 'transparent',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          margin: 0,
          textAlign: 'left',
          width: '100%',
        },
        '#root': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FF8769', // Green background for AppBar
          color: '#ffffff', // White text for AppBar
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: 'linear-gradient(180deg, #AC3287 1%, #E1486F 80%, #FF8769 100%)', // White background for Drawer
          color: '#FFF', // Black text for Drawer
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: '#FFF', // White text for ListItems
          '&:hover': {
            backgroundColor: '#FF9C84', // Orange background on hover
            color: '#FFF', // White text on hover
          },
        },
      },
    },
  },
});

export default theme;
