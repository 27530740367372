import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Marketing.css';

function Marketing() {
    return (
        <div className={styles.container}>

            {/* Hero Section */}
            <section>
                <h1>
                    Orchestrating solutions at your tempo.
                </h1>
                <p>
                    Empowering businesses and individuals with scalable solutions, innovative apps, and creative tools.
                </p>
                <Link to="/services">
                    Explore Our Services
                </Link>
            </section>

            {/* Services Section */}
            <section>
                <div>
                    <h2>Our Services</h2>
                    <p>
                        Discover the range of services we offer to help bring your ideas to life.
                    </p>
                    <Link to="/services">
                        Learn More
                    </Link>
                </div>
            </section>

            {/* Projects Section */}
            <section>
                <div>
                    <h2>Our Projects</h2>
                    <p>
                        Explore the innovative projects we’ve brought to life for clients and ourselves.
                    </p>
                    <Link to="/projects">
                        See Our Work
                    </Link>
                </div>
            </section>

            {/* Blog Section */}
            <section>
                <div>
                    <h2>Our Blog</h2>
                    <p>
                        Read insights, updates, and stories from the Codeductor team.
                    </p>
                    <a
                        href="/blog"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Visit Blog
                    </a>
                </div>
            </section>

            {/* About Section */}
            <section>
                <div>
                    <h2>About Us</h2>
                    <p>
                        Learn more about our mission, values, and the team behind Codeductor.
                    </p>
                    <Link to="/about">
                        Read More
                    </Link>
                </div>
            </section>
        </div>
    );
}

export default Marketing;