function Us() {

    return (
        <div>
            <h1>About Us</h1>
        </div>
    )

}

export default Us;