function EnterpriseSolutions() {

    return (
        <div>
            <h1>Enterprise Solutions</h1>
        </div>
    )

}

export default EnterpriseSolutions;