import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import LogoImageLight from '../../assets/Key-OnDark.png';

const menuItems = [
  {
    label: "Services",
    link: "/services",
    subMenu: [
      { label: "Application Development", link: "/services/app-development" },
      { label: "Graphics, UI and UX Design", link: "/services/design" },
      { label: "SEO Services", link: "/services/seo" },
      { label: "Technical Consulting", link: "/services/consulting" },
      { label: "Developer Training and Coaching", link: "/services/developer-training" },
      { label: "Solutions Architect and Team Building", link: "/services/solutions-architect" },
    ],
  },
  {
    label: "Projects",
    link: "/projects",
    subMenu: [
      { label: "Consumer Facing Apps", link: "/projects/consumer-apps" },
      { label: "Enterprise Solutions", link: "/projects/enterprise-solutions" },
      { label: "Open Source Projects", link: "/projects/open-source" },
    ],
  },
  {
    label: "About",
    link: "/about",
    subMenu: [
      { label: "Us", link: "/about/us" },
      { label: "Contact", link: "/about/contact" },
      { label: "Privacy Policy", link: "/about/privacy-policy" },
      { label: "Terms of Service", link: "/about/terms-of-service" },
    ],
  },
  {
    label: "Blog",
    link: "/blog",
    newTab: true,
  },
];

function Header({ className }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleMenuOpen = (event, menuLabel) => {
    setAnchorEl({ anchor: event.currentTarget, label: menuLabel });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const renderSubMenu = (subMenu) => (
    <Menu
      anchorEl={anchorEl?.anchor}
      open={Boolean(anchorEl) && anchorEl.label === subMenu.label}
      onClose={handleMenuClose}
    >
      {subMenu.subMenu.map((item) => (
        <MenuItem key={item.label} onClick={handleMenuClose}>
          <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Typography>{item.label}</Typography>
          </Link>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <div className={className}>
      <AppBar position="static" sx={{ background: '#FF8F95' }}>
        <Toolbar>
          {/* Logo */}
          <img src={LogoImageLight} alt="Codeductor Logo" style={{ height: '50px' }} />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer" }}
          >
            <Link to="/" style={{ textDecoration: 'none', color: '#FFF' }}>
              Codeductor
            </Link>
          </Typography>

          {/* Desktop Navigation */}
          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
            {menuItems.map((item) =>
              item.subMenu ? (
                <React.Fragment key={item.label}>
                  <Button
                    color="inherit"
                    onClick={(e) => handleMenuOpen(e, item.label)}
                    endIcon={<ExpandMoreIcon />}
                  >
                    {item.label}
                  </Button>
                  {anchorEl && anchorEl.label === item.label && renderSubMenu(item)}
                </React.Fragment>
              ) : (
                <Button
                  key={item.label}
                  color="inherit"
                  component={Link}
                  to={item.link}
                  target={item.newTab ? "_blank" : "_self"}
                >
                  {item.label}
                </Button>
              )
            )}
          </Box>

          {/* Mobile Menu Icon */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              color="inherit"
              edge="end"
              onClick={toggleDrawer(true)}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Navigation */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {menuItems.map((item) => (
              <React.Fragment key={item.label}>
                <ListItem>
                  <Link
                    to={item.link}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <ListItemText primary={item.label} />
                  </Link>
                </ListItem>
                {item.subMenu && (
                  <List component="div" disablePadding>
                    {item.subMenu.map((subItem) => (
                      <ListItem
                        key={subItem.label}
                        sx={{ pl: 4 }}
                      >
                        <Link
                          to={subItem.link}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <ListItemText primary={subItem.label} />
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                )}
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

export default Header;
