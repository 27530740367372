function AppDevelopment() {

    return (
        <div>
            <center>
                <h1 className="text-5xl font-bold mb-6 py-8">
                    Application Development
                </h1>
                <p className="text-lg mb-10">
                    Collaborate in a traditional contracting fashion to develop web, mobile, or cross-platform apps.
                </p>
                <p className="text-lg mb-10">
                    Dedicated personnel, high-quality deliverables, and direct team collaboration.
                </p>
                Why Chose Us?
                <p className="text-lg mb-10">
                    Extensive full-stack and frontend development expertise, specializing in frameworks like React, Vue, Next.js, and Nuxt.js, with a strong focus on creating high-performance, user-centric web applications. Delivered multiple sites with thousands of users, achieving top-tier performance scores and average page response times under 3ms. Proficient in native iOS (Objective-C/Swift) and Android (Kotlin/Java) development, as well as backend technologies like Node.js and Django. Successfully deployed over 20 apps to app stores, with peak user counts exceeding 100,000 and maintaining 4.5+ average ratings. Experienced in collaborating with globally distributed teams across India, Poland, France, and Japan, ensuring seamless development and delivery.
                </p>
            </center>
        </div>
    )

}

export default AppDevelopment;