function Blog() {

    return (
        <div>
            <h1>Blog</h1>
        </div>
    )

}

export default Blog;