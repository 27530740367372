import { Link } from 'react-router-dom';

const services = [
    {
        title: "Application Development",
        callToAction: "Let's Build Your App",
        description: "Empowering businesses and individuals with scalable solutions, innovative apps, and creative tools.",
        link: "/services/app-development",
        linkColor: "bg-orange-500 hover:bg-orange-600",
    },
    {
        title: "Graphics, UI and UX Design",
        callToAction: "Learn About Our Design Services",
        description: "Collaborate to craft the perfect design and develop visual assets like; mockups, sprite sheets, an infographic, and more.",
        link: "/services/design",
        linkColor: "bg-blue-500 hover:bg-blue-600",
    },
    {
        title: "SEO Services",
        callToAction: "Explore Our SEO Services",
        description: "Search Engine Optimization (On-Page, Technical, Content, Keyword) to improve rankings, drive organic traffic, and enhance site visibility.",
        link: "/services/seo",
        linkColor: "bg-green-500 hover:bg-green-600",
    },
    {
        title: "Technical Consulting",
        callToAction: "Connect With Us Consulting Services",
        description: "Consulting services on system architecture, best practices, focus areas, and business brainstorming.",
        link: "/services/consulting",
        linkColor: "bg-purple-500 hover:bg-purple-600",
    },
    {
        title: "Developer Training and Coaching",
        callToAction: "Explore Our Developer Training Services",
        description: "Provide senior-level training, reviews, debugging, and more for your developers to increase productivity and efficiency.",
        link: "/services/developer-training",
        linkColor: "bg-red-500 hover:bg-red-600",
    },
    {
        title: "Solutions Architect and Team Building",
        callToAction: "Learn Move About Our Solutions Architect Services",
        description: "Collaboratively create a business process map, develop a roadmap, and translate requirements into actionable steps for teams. Craft job descriptions, create hiring plans, and conduct technical and cultural interviews to build your team to execute.",
        link: "/services/solutions-architect",
        linkColor: "bg-yellow-500 hover:bg-yellow-600",
    },
]

function ServicesPage() {
    return (
        <div>
            <center>
                <h1 className="text-5xl font-bold mb-6">
                    Our Services
                </h1>
            </center>
            <ol>
                {services.map((service, index) => (
                    <li key={index}>
                        <center>
                            <h1>
                                {service.title}
                            </h1>
                            <p>
                                {service.description}
                            </p>
                            <Link to={service.link}>{service.callToAction}</Link>
                        </center>
                    </li>
                ))}
            </ol>
        </div >
    );
}

export default ServicesPage;