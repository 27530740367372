import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';

// Pages
import LoginPage from './pages/Login/Login.jsx';
import Home from './pages/Home/Home.jsx';
import Marketing from './pages/Marketing/Marketing.jsx';

// Marketing About Pages
import MarketingLander from './pages/Marketing/Marketing.jsx';
import AboutUs from './pages/Marketing/About/Us/Us.jsx';
import AboutContactUs from './pages/Marketing/About/Contact/Contact.jsx';
import AboutTermsOfService from './pages/Marketing/About/TermsOfService/TermsOfService.jsx';
import AboutPrivacyPolicy from './pages/Marketing/About/PrivacyPolicy/PrivacyPolicy.jsx';

// Marketing Blog
import Blog from './pages/Marketing/Blog/Blog.jsx';

// Marketing Products
import Products from './pages/Marketing/Products/Products.jsx';
import UltraRPS from './pages/Marketing/Products/UltraRPS/UltraRPS.jsx';

// Marketing Projects
import Projects from './pages/Marketing/Projects/Projects.jsx';
import ConsumerApps from './pages/Marketing/Projects/ConsumerApps/ConsumerApps.jsx';
import EnterpriseSolutions from './pages/Marketing/Projects/EnterpriseSolutions/EnterpriseSolutions.jsx';
import OpenSource from './pages/Marketing/Projects/OpenSource/OpenSource.jsx';

// Marketing Services
import Services from './pages/Marketing/Services/Services.jsx';
import AppDevelopment from './pages/Marketing/Services/AppDevelopment/AppDevelopment.jsx';
import Consulting from './pages/Marketing/Services/Consulting/Consulting.jsx';
import Design from './pages/Marketing/Services/Design/Design.jsx';
import DeveloperTraining from './pages/Marketing/Services/DeveloperTraining/DeveloperTraining.jsx';
import SEO from './pages/Marketing/Services/SEO/SEO.jsx';
import SolutionsArchitect from './pages/Marketing/Services/SolutionsArchitect/SolutionsArchitect.jsx';

// Components
import Header from './components/Header/Header.jsx';
import Sidebar from './components/Sidebar/Sidebar.jsx';
import Footer from './components/Footer/Footer.jsx';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs.jsx';
import PrivateRoute from './components/PrivateRoute/PrivateRoute.jsx';

// Themes
import '@fontsource/roboto';
import mainTheme from './styles/theme.js';
import './App.css';

// Contexts
import { AuthProvider, useAuth } from './contexts/AuthContext/AuthContext.jsx';

// Hooks
import useIsEmbedded from './hooks/useIsEmbedded.js';

function AppContent() {
  const { isAuthenticated } = useAuth();

  return (
    <div className='app-container'>

      {isAuthenticated && (
        <>
          {<Sidebar />}
          <div className='content'>
            {<Header />}
            {<Breadcrumbs />}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Routes>
                <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
              </Routes>
            </div>
            {<Footer style={{ marginTop: 'auto' }} />}
          </div>
        </>
      )}

      {!isAuthenticated && (
        <div className='app-container'>
          <Header />
          <div className='content'>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/services" element={<Services />} />
              <Route path="/services/app-development" element={<AppDevelopment />} />
              <Route path="/services/consulting" element={<Consulting />} />
              <Route path="/services/design" element={<Design />} />
              <Route path="/services/developer-training" element={<DeveloperTraining />} />
              <Route path="/services/seo" element={<SEO />} />
              <Route path="/services/solutions-architect" element={<SolutionsArchitect />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/about/contact" element={<AboutContactUs />} />
              <Route path="/about/privacy-policy" element={<AboutPrivacyPolicy />} />
              <Route path="/about/terms-of-service" element={<AboutTermsOfService />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/ultra-rps" element={<UltraRPS />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/consumer-apps" element={<ConsumerApps />} />
              <Route path="/projects/enterprise-solutions" element={<EnterpriseSolutions />} />
              <Route path="/projects/open-source" element={<OpenSource />} />
              <Route path="/" element={<MarketingLander />} />
              {/* TODO: Need to add a 404 page */}
              <Route path="*" element={<Marketing />} />
            </Routes>
          </div>
          <Footer className='footer' />
        </div>
      )}

    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
