function Projects() {

    return (
        <div>
            <h1>Projects</h1>
        </div>
    )

}

export default Projects;